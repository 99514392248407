// src/CustomButton.js
import React, {useState} from 'react';
import './TertiaryButton.css'; // Import the CSS file for common styles

const TertiaryButton = ({ text, width, height, borderRadius, onLoginClick }) => {
  const [isTouch, setIsTouch] = useState(false); // To differentiate between touch and mouse events

    const buttonStyle = {
        width: width || 'auto',  // Default to 'auto' if no width is provided
        height: height || 'auto', // Default to 'auto' if no height is provided
        backgroundColor: 'white', // Primary blue background
        color: '#256EFB',          // White text
        border: 'none',           // Remove default border
        borderRadius: borderRadius,      // Rounded corner
        fontSize: '1.05rem',               // Font size
        fontFamily: 'Noto Sans, sans-serif',
        fontWeight: 600,
        position: 'relative',
        cursor: 'pointer',        // Pointer cursor on hover
        transition: 'background-color 0.2s ease, transform 0.2s ease',
        transform: 'scale(1)',// Smooth transitions,
        zIndex: 3
      };

      const hoverStyle = {
        backgroundColor: 'white', // Light background on hover
        transform: 'scale(1.05)',       // Slightly enlarge on hove
      };
    
      const activeStyle = {
        backgroundColor: 'white', // Darker background when clicked
        transform: 'scale(0.95)',       // Slightly shrink on click
      };

  return (
    <button
      className='tertiary-button'
      style={buttonStyle}
      onMouseOver={e => !isTouch && Object.assign(e.target.style, hoverStyle)}
      onMouseOut={e => !isTouch && Object.assign(e.target.style, buttonStyle)}
      onMouseDown={e => !isTouch && Object.assign(e.target.style, activeStyle)}
      onMouseUp={e => !isTouch && Object.assign(e.target.style, hoverStyle)}
      
      // For mobile (touch)
      onTouchStart={e => {
        setIsTouch(true); // Detects touch event
        Object.assign(e.target.style, activeStyle);
      }}
      onTouchEnd={e => Object.assign(e.target.style, buttonStyle)}
      onTouchCancel={e => Object.assign(e.target.style, buttonStyle)} // Reset if touch is canceled

      onClick={onLoginClick}
    >
      {text}
    </button>
  );
};

export default TertiaryButton;
