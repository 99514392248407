import React,{ useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { auth } from './firebase'; // Import the initialized auth instance
import ProtectedRoute from './ProtectedRoute';

// Import your components/pages
import HomePage from './HomePage';
import VisionPage from './VisionPage';
import AuthPopup from './AuthPopup';
import PreregistrationPage from './PreregistrationPage';
import MPreregistrationPage from './MPreregistrationPage';

import MHomePage from './MHomePage';

const App = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const showPopup = () => setIsPopupVisible(true);
  const hidePopup = () => setIsPopupVisible(false);

  const isDesktop = useMediaQuery({ minWidth: 1024 });

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user); // Set user when logged in
      } else {
        setUser(null); // Clear user when not logged in
      }
      setLoading(false);
    });

    return () => unsubscribe(); // Clean up the subscription
  }, []);

  // Redirect base URL ("/") to "/en"
  const RedirectToDefaultLanguage = () => {
    const location = useLocation();
    // Check if the path is just "/" and redirect to "/en" as default
    if (location.pathname === "/") {
      return <Navigate to="/en" replace />;
    }
    return null; // No redirection needed for other paths
  };

  return (
    !loading && (
      <Router>
        <div className="app-container">
          <AuthPopup isVisible={isPopupVisible} onClose={hidePopup} />
          <Routes>
            {/* Default redirect to /en */}
            <Route path="/" element={<RedirectToDefaultLanguage />} />

            {/* English Routes */}
            <Route path="/en" element={isDesktop ? <HomePage onLoginClick={showPopup} /> : <MHomePage onLoginClick={showPopup}/>} />
            <Route path="/en/ourvision" element={<VisionPage onLoginClick={showPopup} />} />
            <Route path="/en/preregistration" element={isDesktop ?
              <ProtectedRoute user={user}>
                <PreregistrationPage />
              </ProtectedRoute> :
              <ProtectedRoute user={user}>
                <MPreregistrationPage />
              </ProtectedRoute>
            }/>

            {/* Dutch Routes */}
            <Route path="/nl" element={isDesktop ? <HomePage onLoginClick={showPopup} /> : <MHomePage onLoginClick={showPopup}/>} />
            <Route path="/nl/ourvision" element={<VisionPage onLoginClick={showPopup} />} />
            <Route path="/nl/preregistration" element={isDesktop ?
              <ProtectedRoute user={user}>
                <PreregistrationPage />
              </ProtectedRoute> :
              <ProtectedRoute user={user}>
                <MPreregistrationPage />
              </ProtectedRoute>
            }/>
          </Routes>
        </div>
      </Router>
    )
  );
};

export default App;
