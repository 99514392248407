// src/CustomButton.js
import React, { useState } from 'react';
import './PrimaryButton.css'; // Import the CSS file for common styles

const PrimaryButton = ({ text, width, height, borderRadius, onLoginClick, fontSize }) => {
  const [isTouch, setIsTouch] = useState(false); // To differentiate between touch and mouse events
    const buttonStyle = {
        width: width || 'auto',  // Default to 'auto' if no width is provided
        height: height || 'auto', // Default to 'auto' if no height is provided
        backgroundColor: '#256EFB', // Primary blue background
        color: 'white',          // White text
        border: 'none',           // Remove default border
        borderRadius: borderRadius,      // Rounded corner
        fontSize: fontSize || '1.05em',               // Font size
        fontFamily: 'Noto Sans, sans-serif',
        fontWeight: 600,
        cursor: 'pointer',        // Pointer cursor on hover
        transition: 'background-color 0.2s ease, transform 0.2s ease',
        transform: 'scale(1)',// Smooth transitions,,
        zIndex: 10
      };

      const hoverStyle = {
        backgroundColor: 'rgba(37, 110, 251, 0.9)', // Light background on hover
        transform: 'scale(1.05)',       // Slightly enlarge on hover
      };
    
      const activeStyle = {
        backgroundColor: 'rgba(37, 110, 251, 0.8)', // Darker background when clicked
        transform: 'scale(0.95)',       // Slightly shrink on click
      };

  return (
    <button
      className='primary-button'
      style={buttonStyle}
      onMouseOver={e => !isTouch && Object.assign(e.target.style, hoverStyle)}
      onMouseOut={e => !isTouch && Object.assign(e.target.style, buttonStyle)}
      onMouseDown={e => !isTouch && Object.assign(e.target.style, activeStyle)}
      onMouseUp={e => !isTouch && Object.assign(e.target.style, hoverStyle)}
      
      // For mobile (touch)
      onTouchStart={e => {
        setIsTouch(true); // Detects touch event
        Object.assign(e.target.style, activeStyle);
      }}
      onTouchEnd={e => Object.assign(e.target.style, buttonStyle)}
      onTouchCancel={e => Object.assign(e.target.style, buttonStyle)} // Reset if touch is canceled

      onClick={onLoginClick}
    >
      {text}
    </button>
  );
};

export default PrimaryButton;
