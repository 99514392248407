import React from 'react';
import './SubscriptionForm.css'; // Import the CSS file for common styles


const SubscriptionForm = ({width, height, email, setEmail, fontSize}) => {
  const inputStyle = {
    width: '100%',
    height: '100%',          // Full width input
    padding: '10px',         // Padding inside the input box
    fontSize: fontSize || '1rem',
    border: 'none',      // Font size for the text
    borderRadius: '12px',     // Rounded corners // Light gray border
    backgroundColor: 'white', // White background color
    color: '#021E3E',
    fontFamily: 'Noto Sans, sans-serif',
    fontWeight: 500,
    zIndex:4
  };

  const formStyle = {
    width: '100%',  // Default to 100% if no width is provided
    height: '100%', // Default to 100% if no height is provided
    display: 'flex',          // Flexbox to center content
    alignItems: 'center',     // Center content vertically
    justifyContent: 'center', // Center content horizontally
  };

  return (
    <form style={formStyle}>
      <input
        type="email"
        value={email}
        placeholder="Enter your email"
        onChange={(e) => setEmail(e.target.value)}
        style={inputStyle}
      />
    </form>
  );
};

export default SubscriptionForm;