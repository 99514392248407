import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { auth } from './firebase'; // Import auth for sign-out example
import './HomePage.css';
import Tcurl1 from './assets/title_curl_1.svg';
import Tcurl2 from './assets/title_curl_2.svg';
import Tcards from './assets/title_cards_5.png';
import Secundary from './assets/secondary_blue1.svg';
import FSecundary from './assets/secondary_blue2.svg';
import FIcon1 from './assets/twitter1.png';
import FIcon2 from './assets/linkedin1.png';
import FIcon3 from './assets/facebook1.png';
import FIcon4 from './assets/instagram1.png';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import TertiaryButton from './TertiaryButton';
import SubscriptionForm from './SubscriptionForm';
import Hcurl1 from './assets/howto_curl1.svg';
import Hcurl2 from './assets/howto_curl2.svg';
import Hcurl3 from './assets/howto_curl3.svg';
import Hcard1 from './assets/howto_cardimg1.png';
import Hcard2 from './assets/howto_cardimg2.png';
import Hcard3 from './assets/howto_cardimg3.png';
import Hcard4 from './assets/howto_cardimg4.png';
import Scurl1 from './assets/socials_curl1.svg';
import Scurl2 from './assets/socials_curl2.svg';
import Simg1 from './assets/socials_img1.png';
import Simg2 from './assets/socials_img2.png';
import Simg5 from './assets/socials_img4.png';
import Simg4 from './assets/socials_img3.png';
import Simg3 from './assets/socials_img5.png';
import Simg6 from './assets/socials_img6.png';
import signInWithGoogle from './signInGoogle';

import { db } from './firebase'; // Import your Firestore instance
import { collection, addDoc } from 'firebase/firestore';

import { Link } from 'react-router-dom';



const HomePage = ({onLoginClick} ) => {

  const location = useLocation();
  const langPrefix = location.pathname.split('/')[1] || 'en';
  
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);


  useEffect(() => {
    const img = new Image();
    img.src = Tcards;
  }, []);


  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const docRef = await addDoc(collection(db, 'emailFeedback'), {
        email: email,
        timestamp: new Date()
      });
      console.log('Document written with ID: ', docRef.id);
      setEmail('');
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  return (
    <div className="App">
     <section id="title-page" className="title-page">
        <div className="title-page-container">
          <div className="title-curl1-container">
            <img src={Tcurl1} alt="Title Curl1" className="title-curl1" />
          </div>
          <div className="title-curl2-container">
            <img src={Tcurl2} alt="Title Curl2" className="title-curl2" />
          </div>
          <div className="title-secundary-container">
          <Link to={`/${langPrefix}`}>
            <img src={Secundary} alt="title Secundary" className="title-secundary" />
          </Link>
          </div>
          <div className="title-nav-top-container">
            <div className="title-nav-about-cont">
              <a href="#" onClick={() => scrollToSection('title-page')} className="nav-link">Home</a>
            </div>
            <div className="title-nav-gallery-cont">
                <a href="#" onClick={() => scrollToSection('howto-page')} className="nav-link">About</a>
            </div>
            <div className="title-nav-vision-cont">
                <a href="#" onClick={() => scrollToSection('socials-page')} className="nav-link">Gallery</a>
            </div>
            <div className="title-nav-contact-cont">
                <Link to={`/${langPrefix}/ourvision`} className="nav-link">Our vision</Link>
            </div>
          </div>
          <div className="title-nav-buttons-container">
          <SecondaryButton text="Login" width="8.3vw" height="5.10vh" borderRadius={"8px"} onLoginClick={onLoginClick} />
          <PrimaryButton text="Sign up" width="8.5vw" height="5.10vh" borderRadius={"8px"} onLoginClick={onLoginClick} />
          </div>
          <div className="title-cards-container">
            <img src={Tcards} alt="Creating friendship or meeting people during activities like game night or paintball" className="title-cards" />
          </div>
          <div className="blurred-circle"></div>
          <div className="title-jointly-cont">
            <h1 className="title-jointly">Jointly</h1>
          </div>
          <div className="title-slogan-cont">
            <span className="title-slogan">Experience it together</span>
          </div>
          <div className="title-intro-cont">
            <h2 className="title-intro">The platform for meeting</h2>
            <h2 className="title-intro">the people you want by</h2>
            <h2 className="title-intro">doing the activities you like</h2>
          </div>
          <div className="title-getstarted-cont">
          <PrimaryButton text="Join Jointly" width="100%" height="100%" borderRadius={"10px"} onLoginClick={onLoginClick}/>
          </div>
        </div>
      </section>

      <section id="howto-page" className="howto-page">
        <div className="howto-page-container">
          <div className="howto-header-cont">
            <h2 className="howto-header">What is Jointly?</h2>
          </div>
          <div className="howto-desc-cont">
            <span className="howto-desc">Whether you are new in town, bored, or just looking to expand your social circle, Jointly is here for you. Get</span>
            <span className="howto-desc">to know the people you choose by doing activities you like. <span className="highlight">Find friends nearby, network with new business</span></span>
            <span className="howto-desc"><span className="highlight">contacts, or gather a group</span> for a one-time adventure. It's simple!</span>
          </div>
          <div className="howto-curl1-container">
            <img src={Hcurl1} alt="Howto Curl1" className="howto-curl1" />
          </div>
          <div className="howto-curl2-container">
            <img src={Hcurl2} alt="Howto Curl2" className="howto-curl2" />
          </div>
          <div className="howto-curl3-container">
            <img src={Hcurl3} alt="Howto Curl3" className="howto-curl3" />
          </div>
          <div className="howto-getstarted-cont">
          <PrimaryButton text="Get Started" width="100%" height="100%" borderRadius={"10px"} onLoginClick={onLoginClick} />
          </div>
          <div className="howto-card1-cont">
            <div className="howto-card1t-cont">
              <img src={Hcard1} alt="Create small-scale activities on this friendship app and find people to do them with" className="howto-cardimg" />
            </div>
            <div className="howto-card1b-cont">
            <div className="howto-cardtext-cont">
              <p className="howto-cardtext">1. <span className="highlight">Create the activity</span> you want, set the details and decide who can apply</p>
              </div>
            </div>
          </div>
          <div className="howto-card2-cont">
            <div className="howto-card2t-cont"><img src={Hcard2} alt="Meet like-minded people by deciding who can join" className="howto-cardimg" /></div>
            <div className="howto-card2b-cont">
            <div className="howto-cardtext-cont">
              <p className="howto-cardtext">2. <span className="highlight">Review and accept applications </span>from potential joiners</p>
              </div>
            </div>
          </div>
          <div className="howto-card3-cont">
            <div className="howto-card3t-cont"><img src={Hcard3} alt="Connect with new people via chat and after in real-life for your activity" className="howto-cardimg" /></div>
            <div className="howto-card3b-cont">
            <div className="howto-cardtext-cont">
              <p className="howto-cardtext">3. <span className="highlight">Connect with them</span> in the activity hub and schedule your activity</p>
              </div>
            </div>
          </div>
          <div className="howto-card4-cont">
            <div className="howto-card4t-cont"><img src={Hcard4} alt="Enjoy meeting new people while an activity you like" className="howto-cardimg" /></div>
            <div className="howto-card4b-cont">
              <div className="howto-cardtext-cont">
              <p className="howto-cardtext">4. Enjoy the experience <span className="highlight">together!</span></p>
              </div>
            </div>
          </div>
          <div className="blurred-ellips"></div>
        </div>
      </section>

      <section id="socials-page" className="socials-page">
        <div className="socials-page-container">
          <div className="socials-header-cont">
            <span className="socials-header">Jointly in action</span>
            <span className="socials-header">Shared experiences</span>
          </div>
          <div className="socials-blurred-circle"></div>
          <div className="socials-curl1-container">
            <img src={Scurl1} alt="Howto curl1" className="socials-curl1" />
          </div>
          <div className="socials-curl2-container">
            <img src={Scurl2} alt="Howto curl2" className="socials-curl2" />
          </div>
          <div className="socials-img1-container">
            <img src={Simg1} alt="Meet people during workshops" className="socials-img" />
          </div>
          <div className="socials-img2-container">
            <img src={Simg2} alt="Do bowling with new friends via the app" className="socials-img" />
          </div>
          <div className="socials-img3-container">
            <img src={Simg3} alt="Find sport group in Belgian cities with the Jointly App" className="socials-img" />
          </div>
          <div className="socials-img4-container">
            <img src={Simg4} alt="Escape room with new connections" className="socials-img" />
          </div>
          <div className="socials-img5-container">
            <img src={Simg5} alt="Find people to go for a drink with in Antwerp, Gent, Brussels or Leuven" className="socials-img" />
          </div>
          <div className="socials-img6-container">
            <img src={Simg6} alt="Have fun while meeting new people doing your hobby" className="socials-img" />
          </div>
          <div className="socials-button-container">
          <TertiaryButton text="Follow us on Instagram" width="100%" height="5vh" borderRadius={"10px"} />
          </div>
          <div className="socials-subscribe-container">
            <span className="socials-subscribe-header">Help us!</span>
            <div className="socials-desc-cont">
              <p className="socials-desc">The Jointly journey has only just started and we need your help. Let us know if we can contact you to ask for your input and if we can keep you up to date.</p>
            </div>
            <div className="socials-form-cont">
              <div className="socials-form-subscription-cont">
              <SubscriptionForm width="64.28%" height="100%" email={email} setEmail={setEmail} />
              </div>
              <PrimaryButton text="Contact me!" width="33.42%" height="100%" borderRadius={"12px"} onLoginClick={handleSubmit} />
            </div>
          </div>
        </div>
      </section>


      <section className="footer-page">
        <div className="footer-page-container">
          <div className="footer-secundary-container">
          <Link to={`/${langPrefix}`}>
            <img src={FSecundary} alt="title Secundary" className="footer-secundary" />
            </Link>
          </div>
          <div className="footer-desc-cont">
            <p className="footer-desc">Jointly makes building connections natural and easy. Our goal is to help you have a
            great time doing activities you love while meeting like-minded people.</p>
          </div>
          <div className="footer-copy-cont">
            <p className="footer-copy">@2024 Jointly. All rights reserved.</p>
          </div>
          <div className="footer-links-cont">
            <Link to={`/${langPrefix}`} className="footer-link">Home</Link>
            <Link to={`/${langPrefix}#howto-page`} className="footer-link">About</Link>
            <Link to={`/${langPrefix}#socials-page`} className="footer-link">Gallery</Link>
            <Link to={`/${langPrefix}/ourvision`} className="footer-link">Our vision</Link>
          </div>
          <div className="footer-icon1-container">
            <div className="footer-icon2-container">
              <img src={FIcon1} alt="title Secundary" className="footer-icon" />
              <img src={FIcon2} alt="title Secundary" className="footer-icon" />
            </div>
            <div className="footer-icon2-container">
              <img src={FIcon3} alt="title Secundary" className="footer-icon" />
              <img src={FIcon4} alt="title Secundary" className="footer-icon" />
            </div>
          </div>
          <div className="footer-terms-cont">
            <p className="footer-copy">Terms and conditions</p>
            <div className="footer-spacer"></div>
            <p className="footer-copy">Privacy policy</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomePage;
