// src/AuthPopup.js


// src/AuthPopup.js
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; 
import { auth } from './firebase';
import Banner from './Banner'; // Import the Banner component



let authUiInstance = null; // Singleton for AuthUI instance

function AuthPopup({ isVisible, onClose }) {
  const popupRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState(''); // State for error messages
  const [showBanner, setShowBanner] = useState(false); // State to control banner visibility
  const navigate = useNavigate(); // Hook to programmatically navigate
  const location = useLocation();
  const langPrefix = location.pathname.split('/')[1] || 'en';


  useEffect(() => {
    
    if (isVisible) {
        const user = auth.currentUser;

      if (user) {
        // User is already logged in
        console.log('User is already logged in:', user);
        setShowBanner(true);
        onClose(); // Close the auth popup
        // Optionally navigate or handle authenticated state
        return;
      }


      const uiConfig = {
        signInOptions: [
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
        signInFlow: 'popup',
        credentialHelper: window.firebaseui.auth.CredentialHelper.NONE,
        callbacks: {
            signInSuccessWithAuthResult: async (authResult) => {
                setErrorMessage('');
                onClose();
                const user = authResult.user;
                navigate(`/${langPrefix}/preregistration`);
                return false;
        },
        signInFailure: (error) => {
            console.error('Sign-in failure:', error);
            // Handle failure
            return Promise.resolve();
          },
      }
    }

      // Initialize AuthUI if not already initialized
      if (!authUiInstance) {
        authUiInstance = new window.firebaseui.auth.AuthUI(auth);
      }

      authUiInstance.start('#firebaseui-auth-container', uiConfig);

      const handleEscape = (event) => {
        if (event.key === 'Escape') {
          onClose();
        }
      };
      document.addEventListener('keydown', handleEscape);

      return () => {
        document.removeEventListener('keydown', handleEscape);
        if (authUiInstance) {
          authUiInstance.reset();
        }
      };
    }
  }, [isVisible, onClose]);

  const handleOutsideClick = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  const handleBannerClose = () => {
    setShowBanner(false); // Hide the banner
    navigate(`/${langPrefix}/preregistration`);
  };

  if (!isVisible && !showBanner) return null;

  return (
   <>
    {showBanner && (
        <Banner 
          message="User still logged in, redirecting"
          duration={3400}
          onClose={handleBannerClose}
        />
      )}

    {!showBanner && (<div className="overlay" onClick={handleOutsideClick}>
      <div className="popup" ref={popupRef}>
        <button className="close-button" onClick={onClose}>X</button>
        <div id="firebaseui-auth-container"></div>
      </div>
    </div>
    )}
    </>
  );
}

export default AuthPopup;

