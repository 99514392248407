import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './PreregistrationPage.css';
import { auth } from './firebase';
import Secundary from './assets/secondary_blue1.svg';
import FIcon1 from './assets/twitter1.png';
import FIcon2 from './assets/linkedin1.png';
import FIcon3 from './assets/facebook1.png';
import FIcon4 from './assets/instagram1.png';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import { Link } from 'react-router-dom';
import Tcards from './assets/vision_title_cards.png'
import FSecundary from './assets/secondary_blue2.svg';
import Hcurl1 from './assets/helpus_curl_1.svg';
import SubscriptionForm3 from './SubscriptionForm3';
import TertiaryButton from './TertiaryButton';


import { db } from './firebase'; // Import your Firestore instance
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook




const PreregistrationPage = () => {
  const [user, setUser] = useState(null); // Stores the user object or null if not logged in
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Hook to programmatically navigate

  const location = useLocation();
  const langPrefix = location.pathname.split('/')[1] || 'en';


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user); // Set user when logged in
        setName(user.displayName)
      } else {
        setUser(null); // Clear user when not logged in
      }
      setLoading(false)
    });

    return () => unsubscribe(); // Clean up the subscription
  }, []);

  const [feedback, setFeedback] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const docRef = await addDoc(collection(db, 'preregFeedback'), {
        feedback: feedback,
        user: user.email,
        timestamp: new Date()
      });
      console.log('Document written with ID: ', docRef.id);
      setFeedback('');
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  const handleLogout = () => {
    auth.signOut()
      .then(() => {
        console.log("User signed out successfully.");
        navigate(`/${langPrefix}`);
        // You can also redirect the user to a different page after logging out
        // For example, you can use navigate('/') to go to the homepage
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };


  return ( !loading && 
    <div className="App">
     <section id='prereg-page' className="prereg-page">
        <div className="pre-page-container">
        <div className="prereg-secundary-container">
            <img src={Secundary} alt="title Secundary" className="title-secundary" />
          </div>
          <div className="prereg-nav-buttons-container">
          <SecondaryButton text="Unregister" width="8.5vw" height="5.10vh" borderRadius={"8px"} />
          <PrimaryButton text="Log out" width="8.3vw" height="5.10vh" borderRadius={"8px"} onLoginClick={handleLogout} />
          </div>
          <div className="prereg-blurred-circle"></div>
          <div className="prereg-header-cont">
            <span className="prereg-header">Welcome, {name}!</span>
        </div>
        <div className="prereg-desc-cont">
      <p className="prereg-desc">Thank you for your preregistration. Unfortunately, the Jointly app has not launched yet, but we will keep you up to date via email. We expect it to be launched in Nov 2024.<br></br><br></br>
        Let us know below which activities you'd like to find on Jointly for a chance to join our exclusive launch events! You can also let us know general expectations or feedback on the platform. All input is appreciated!<br></br><br></br>The Jointly team</p>
        </div>
        
        <div className="prereg-form-cont">
              <div className="prereg-form-feedback-cont">
                <SubscriptionForm3 width="100%" height="100%" feedback={feedback} setFeedback={setFeedback}/>
              </div>
              <PrimaryButton text="Submit" width="25%" height="16%" borderRadius={"12px"} onLoginClick={handleSubmit}/>
          </div>

        </div>
      </section>


      <section className="footer-page">
        <div className="footer-page-container">
          <div className="footer-secundary-container">
            <img src={FSecundary} alt="title Secundary" className="footer-secundary" />
          </div>
          <div className="footer-desc-cont">
            <p className="footer-desc">Jointly makes building connections natural and easy. Our goal is to help you have a
            great time doing activities you love while meeting like-minded people.</p>
          </div>
          <div className="footer-copy-cont">
            <p className="footer-copy">@2024 Jointly. All rights reserved.</p>
          </div>
          <div className="footer-links-cont">
          <Link to='/#title-page' className="footer-link">Home</Link>
            <Link to='/#howto-page' className="footer-link">About</Link>
            <Link to='/#socials-page' className="footer-link">Gallery</Link>
            <Link to='/ourvision#title-page' className="footer-link">Our vision</Link>
          </div>
          <div className="footer-icon1-container">
            <div className="footer-icon2-container">
              <img src={FIcon1} alt="title Secundary" className="footer-icon" />
              <img src={FIcon2} alt="title Secundary" className="footer-icon" />
            </div>
            <div className="footer-icon2-container">
              <img src={FIcon3} alt="title Secundary" className="footer-icon" />
              <img src={FIcon4} alt="title Secundary" className="footer-icon" />
            </div>
          </div>
          <div className="footer-terms-cont">
            <p className="footer-copy">Terms and conditions</p>
            <div className="footer-spacer"></div>
            <p className="footer-copy">Privacy policy</p>
          </div>
        </div>
      </section>
    </div>
  );}

export default PreregistrationPage;