import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { auth } from './firebase'; // Import auth for sign-out example
import './MPreregistrationPage.css';
import { Link } from 'react-router-dom';
import Secundary from './assets/secondary_blue1.svg';
import FSecundary from './assets/secondary_blue2.svg';

import PrimaryButton from './PrimaryButton';
import SubscriptionForm3 from './SubscriptionForm3';
import FIcon1 from './assets/twitter1.png';
import FIcon2 from './assets/linkedin1.png';
import FIcon3 from './assets/facebook1.png';
import FIcon4 from './assets/instagram1.png';

import { db } from './firebase'; // Import your Firestore instance
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook





const MPreregistrationPage = () => {

  const [user, setUser] = useState(null); // Stores the user object or null if not logged in
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);
  const [feedback, setFeedback] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate(); // Hook to programmatically navigate
  const location = useLocation();
  const langPrefix = location.pathname.split('/')[1] || 'en';


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user); // Set user when logged in
        setName(user.displayName)
      } else {
        setUser(null); // Clear user when not logged in
      }
      setLoading(false)
      console.log(user.displayName)
    });

    return () => unsubscribe(); // Clean up the subscription
  }, []);


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    const handleTouchOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    // Add event listeners
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleTouchOutside);

    // Clean up event listeners
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleTouchOutside);
    };
  }, []);

  const handleLogout = () => {
    auth.signOut()
      .then(() => {
        console.log("User signed out successfully.");
        navigate(`/${langPrefix}/`);
        // You can also redirect the user to a different page after logging out
        // For example, you can use navigate('/') to go to the homepage
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const docRef = await addDoc(collection(db, 'preregFeedback'), {
        feedback: feedback,
        user: user.email,
        timestamp: new Date()
      });
      console.log('Document written with ID: ', docRef.id);
      setFeedback('');
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  const handleHomeClick = (e) => {
    e.preventDefault();  // Prevent the default link behavior
    window.location.reload();  // Refresh the page
  };

  return (
    <div className="App">
      <section id="m-title-page" className="m-title-page">
        <div className='m-title-page-container'>
          <nav className="navbar" ref={menuRef}>
            <div className="mtitle-secundary-container" onClick={handleHomeClick}>
              <img src={Secundary} alt="mtitle Secundary" className="mtitle-secundary" />
            </div>
            <div className="hamburger-menu" onClick={toggleMenu}>
              {isMenuOpen? <i id="close-icon" className="fas fa-times"></i>:<i id="hamburger-icon" className="fas fa-bars"></i>}
            </div>
            <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
              <li><a href="#section2" onClick={handleLogout} >Log out</a></li>
            </ul>
          </nav>

          <div className="mprereg-title-cont">
            <span className="mprereg-title">Welcome, {name}!</span>
          </div>

          <div className="mprereg-desc-cont">
            <p className="mprereg-desc">Thank you for your preregistration. Unfortunately, the Jointly app has not launched yet, but we will keep you up to date via email. We expect it to be launched in Nov 2024.<br></br><br></br>
            Let us know below which activities you'd like to find on Jointly or what your general expectations are of the platform for a chance to join our exclusive launch events!<br></br><br></br>The Jointly team</p>
          </div>

          <div className="mprereg-form-cont">
            <SubscriptionForm3 width="100%" height="100%" feedback={feedback} setFeedback={setFeedback}/>
          </div>
          <div className="mprereg-submit-cont">
            <PrimaryButton text="Submit" width="100%" height="100%" borderRadius={"12px"} onLoginClick={handleSubmit}/>
          </div>


        </div>
      </section>

      <section id="m-footer-page2" className="m-footer-page2">
        <div className='m-footer-page-container2'>

        <div className="mfooter-secundary-container">
          <Link to="/" className='mfooter-secundary-link'>
            <img src={FSecundary} alt="mtitle Secundary" className="mfooter-secundary" />
          </Link>
        </div>

        <div className="mfooter-desc-container">
          <span className='mfooter-desc'>Jointly makes building connections natural and easy. Our goal is to help you have a great time doing activities you love while meeting like-minded people.</span>
        </div>

        <div className="mfooter-icons-container">
          <img src={FIcon1} alt="title Secundary" className="mfooter-icon" />
          <img src={FIcon2} alt="title Secundary" className="mfooter-icon" />
          <img src={FIcon3} alt="title Secundary" className="mfooter-icon" />
          <img src={FIcon4} alt="title Secundary" className="mfooter-icon" />
        </div>
        <div className="line"></div>

        <div className='mfooter-links-outercont2'>
          <div className='mfooter-links-innercont'>
            <div className='mfooter-link-left' >
              <span className='mfooter-link' onClick={handleLogout}>Log out</span>
            </div>
            <div className='mfooter-link-right'>
              <span className='mfooter-link'>Delete Account</span>
            </div>
          </div>
        </div>

        <div className="line2"></div>

        <div className="mfooter-copyright-container">
          <span className='mfooter-copyright-left'>@2024 Jointly. All rights reserved.</span>
        </div>

        <div className="mfooter-terms-container">
          <span className='mfooter-copyright-left'>Terms and Conditions</span>
          <span className='mfooter-copyright-right'>Privacy Policy</span>
        </div>

        </div>
        </section>

    </div>)}


export default MPreregistrationPage;
